/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOnIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 14.8c-.2 0-.4-.1-.5-.2l-3-3c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l2.5 2.5 9.5-9.5c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-10 10c-.3.1-.5.2-.7.2z" />
		<path d="M19 21.8H5c-1.5 0-2.8-1.2-2.8-2.8V5c0-1.5 1.2-2.8 2.8-2.8h11c.4 0 .8.3.8.8s-.4.8-.8.8H5c-.7 0-1.2.5-1.2 1.2v14c0 .7.6 1.2 1.2 1.2h14c.7 0 1.2-.6 1.2-1.2v-7c0-.4.3-.8.8-.8s.8.3.8.8v7c0 1.5-1.3 2.8-2.8 2.8z" />
	</svg>
))
CheckboxOnIconSvg.displayName = 'CheckboxOnIconSvg'

const CheckboxOnIcon = forwardRef((props, ref) => <Icon component={CheckboxOnIconSvg} ref={ref} {...props} />)
CheckboxOnIcon.displayName = 'CheckboxOnIcon'

CheckboxOnIcon.defaultProps = { ...Icon.defaultProps }
CheckboxOnIcon.propTypes = { ...Icon.propTypes }

export default CheckboxOnIcon
export { CheckboxOnIconSvg }
