/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadUpIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M18 15.8c-.2 0-.4-.1-.5-.2L12 10.1l-5.5 5.5c-.3.3-.8.3-1.1 0s-.3-.8 0-1.1l6-6c.3-.3.8-.3 1.1 0l6 6c.3.3.3.8 0 1.1-.1.1-.3.2-.5.2z" />
	</svg>
))
ArrowheadUpIconSvg.displayName = 'ArrowheadUpIconSvg'

const ArrowheadUpIcon = forwardRef((props, ref) => <Icon component={ArrowheadUpIconSvg} ref={ref} {...props} />)
ArrowheadUpIcon.displayName = 'ArrowheadUpIcon'

ArrowheadUpIcon.defaultProps = { ...Icon.defaultProps }
ArrowheadUpIcon.propTypes = { ...Icon.propTypes }

export default ArrowheadUpIcon
export { ArrowheadUpIconSvg }
